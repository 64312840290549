// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-aspx-js": () => import("./../../../src/pages/about-us.aspx.js" /* webpackChunkName: "component---src-pages-about-us-aspx-js" */),
  "component---src-pages-advent-calendar-aspx-js": () => import("./../../../src/pages/advent-calendar.aspx.js" /* webpackChunkName: "component---src-pages-advent-calendar-aspx-js" */),
  "component---src-pages-affiliates-aspx-js": () => import("./../../../src/pages/affiliates.aspx.js" /* webpackChunkName: "component---src-pages-affiliates-aspx-js" */),
  "component---src-pages-audio-editor-aspx-js": () => import("./../../../src/pages/audio-editor.aspx.js" /* webpackChunkName: "component---src-pages-audio-editor-aspx-js" */),
  "component---src-pages-avs-audio-editor-aspx-js": () => import("./../../../src/pages/avs-audio-editor.aspx.js" /* webpackChunkName: "component---src-pages-avs-audio-editor-aspx-js" */),
  "component---src-pages-avs-editor-aspx-js": () => import("./../../../src/pages/avs-editor.aspx.js" /* webpackChunkName: "component---src-pages-avs-editor-aspx-js" */),
  "component---src-pages-avs-free-audio-converter-aspx-js": () => import("./../../../src/pages/avs-free-audio-converter.aspx.js" /* webpackChunkName: "component---src-pages-avs-free-audio-converter-aspx-js" */),
  "component---src-pages-avs-free-disc-creator-aspx-js": () => import("./../../../src/pages/avs-free-disc-creator.aspx.js" /* webpackChunkName: "component---src-pages-avs-free-disc-creator-aspx-js" */),
  "component---src-pages-avs-free-document-converter-aspx-js": () => import("./../../../src/pages/avs-free-document-converter.aspx.js" /* webpackChunkName: "component---src-pages-avs-free-document-converter-aspx-js" */),
  "component---src-pages-avs-free-image-converter-aspx-js": () => import("./../../../src/pages/avs-free-image-converter.aspx.js" /* webpackChunkName: "component---src-pages-avs-free-image-converter-aspx-js" */),
  "component---src-pages-avs-free-media-player-aspx-js": () => import("./../../../src/pages/avs-free-media-player.aspx.js" /* webpackChunkName: "component---src-pages-avs-free-media-player-aspx-js" */),
  "component---src-pages-avs-free-photo-editor-aspx-js": () => import("./../../../src/pages/avs-free-photo-editor.aspx.js" /* webpackChunkName: "component---src-pages-avs-free-photo-editor-aspx-js" */),
  "component---src-pages-avs-free-registry-cleaner-aspx-js": () => import("./../../../src/pages/avs-free-registry-cleaner.aspx.js" /* webpackChunkName: "component---src-pages-avs-free-registry-cleaner-aspx-js" */),
  "component---src-pages-avs-free-video-converter-aspx-js": () => import("./../../../src/pages/avs-free-video-converter.aspx.js" /* webpackChunkName: "component---src-pages-avs-free-video-converter-aspx-js" */),
  "component---src-pages-avs-slideshow-maker-aspx-js": () => import("./../../../src/pages/avs-slideshow-maker.aspx.js" /* webpackChunkName: "component---src-pages-avs-slideshow-maker-aspx-js" */),
  "component---src-pages-avs-special-offer-aspx-js": () => import("./../../../src/pages/avs-special-offer.aspx.js" /* webpackChunkName: "component---src-pages-avs-special-offer-aspx-js" */),
  "component---src-pages-avs-special-offer-autumn-aspx-js": () => import("./../../../src/pages/avs-special-offer-autumn.aspx.js" /* webpackChunkName: "component---src-pages-avs-special-offer-autumn-aspx-js" */),
  "component---src-pages-avs-special-offer-halloween-aspx-js": () => import("./../../../src/pages/avs-special-offer-halloween.aspx.js" /* webpackChunkName: "component---src-pages-avs-special-offer-halloween-aspx-js" */),
  "component---src-pages-avs-video-cutter-aspx-js": () => import("./../../../src/pages/avs-video-cutter.aspx.js" /* webpackChunkName: "component---src-pages-avs-video-cutter-aspx-js" */),
  "component---src-pages-avs-video-editor-aspx-js": () => import("./../../../src/pages/avs-video-editor.aspx.js" /* webpackChunkName: "component---src-pages-avs-video-editor-aspx-js" */),
  "component---src-pages-avs-video-editor-imovie-aspx-js": () => import("./../../../src/pages/avs-video-editor-imovie.aspx.js" /* webpackChunkName: "component---src-pages-avs-video-editor-imovie-aspx-js" */),
  "component---src-pages-avs-video-editor-unlimited-aspx-js": () => import("./../../../src/pages/avs-video-editor-unlimited.aspx.js" /* webpackChunkName: "component---src-pages-avs-video-editor-unlimited-aspx-js" */),
  "component---src-pages-avs-video-editor-year-aspx-js": () => import("./../../../src/pages/avs-video-editor-year.aspx.js" /* webpackChunkName: "component---src-pages-avs-video-editor-year-aspx-js" */),
  "component---src-pages-avs-video-maker-aspx-js": () => import("./../../../src/pages/avs-video-maker.aspx.js" /* webpackChunkName: "component---src-pages-avs-video-maker-aspx-js" */),
  "component---src-pages-avs-video-remaker-aspx-js": () => import("./../../../src/pages/avs-video-remaker.aspx.js" /* webpackChunkName: "component---src-pages-avs-video-remaker-aspx-js" */),
  "component---src-pages-avs-video-remaker-giveaway-aspx-js": () => import("./../../../src/pages/avs-video-remaker-giveaway.aspx.js" /* webpackChunkName: "component---src-pages-avs-video-remaker-giveaway-aspx-js" */),
  "component---src-pages-avs-video-screen-recorder-aspx-js": () => import("./../../../src/pages/avs-video-screen-recorder.aspx.js" /* webpackChunkName: "component---src-pages-avs-video-screen-recorder-aspx-js" */),
  "component---src-pages-downloads-aspx-js": () => import("./../../../src/pages/downloads.aspx.js" /* webpackChunkName: "component---src-pages-downloads-aspx-js" */),
  "component---src-pages-education-aspx-js": () => import("./../../../src/pages/education.aspx.js" /* webpackChunkName: "component---src-pages-education-aspx-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-installed-aspx-js": () => import("./../../../src/pages/installed.aspx.js" /* webpackChunkName: "component---src-pages-installed-aspx-js" */),
  "component---src-pages-installed-audio-converter-aspx-js": () => import("./../../../src/pages/installed-audio-converter.aspx.js" /* webpackChunkName: "component---src-pages-installed-audio-converter-aspx-js" */),
  "component---src-pages-installed-audio-editor-aspx-js": () => import("./../../../src/pages/installed-audio-editor.aspx.js" /* webpackChunkName: "component---src-pages-installed-audio-editor-aspx-js" */),
  "component---src-pages-installed-pack-aspx-js": () => import("./../../../src/pages/installed-pack.aspx.js" /* webpackChunkName: "component---src-pages-installed-pack-aspx-js" */),
  "component---src-pages-installed-video-converter-aspx-js": () => import("./../../../src/pages/installed-video-converter.aspx.js" /* webpackChunkName: "component---src-pages-installed-video-converter-aspx-js" */),
  "component---src-pages-installed-video-editor-aspx-js": () => import("./../../../src/pages/installed-video-editor.aspx.js" /* webpackChunkName: "component---src-pages-installed-video-editor-aspx-js" */),
  "component---src-pages-installed-video-remaker-aspx-js": () => import("./../../../src/pages/installed-video-remaker.aspx.js" /* webpackChunkName: "component---src-pages-installed-video-remaker-aspx-js" */),
  "component---src-pages-license-agreement-aspx-js": () => import("./../../../src/pages/license-agreement.aspx.js" /* webpackChunkName: "component---src-pages-license-agreement-aspx-js" */),
  "component---src-pages-moneyback-aspx-js": () => import("./../../../src/pages/moneyback.aspx.js" /* webpackChunkName: "component---src-pages-moneyback-aspx-js" */),
  "component---src-pages-mov-video-editor-aspx-js": () => import("./../../../src/pages/mov-video-editor.aspx.js" /* webpackChunkName: "component---src-pages-mov-video-editor-aspx-js" */),
  "component---src-pages-padfiles-aspx-js": () => import("./../../../src/pages/padfiles.aspx.js" /* webpackChunkName: "component---src-pages-padfiles-aspx-js" */),
  "component---src-pages-partners-aspx-js": () => import("./../../../src/pages/partners.aspx.js" /* webpackChunkName: "component---src-pages-partners-aspx-js" */),
  "component---src-pages-powerful-video-maker-aspx-js": () => import("./../../../src/pages/powerful-video-maker.aspx.js" /* webpackChunkName: "component---src-pages-powerful-video-maker-aspx-js" */),
  "component---src-pages-precise-video-cutting-tools-aspx-js": () => import("./../../../src/pages/precise-video-cutting-tools.aspx.js" /* webpackChunkName: "component---src-pages-precise-video-cutting-tools-aspx-js" */),
  "component---src-pages-privacy-aspx-js": () => import("./../../../src/pages/privacy.aspx.js" /* webpackChunkName: "component---src-pages-privacy-aspx-js" */),
  "component---src-pages-register-ac-aspx-js": () => import("./../../../src/pages/register-ac.aspx.js" /* webpackChunkName: "component---src-pages-register-ac-aspx-js" */),
  "component---src-pages-register-aspx-js": () => import("./../../../src/pages/register.aspx.js" /* webpackChunkName: "component---src-pages-register-aspx-js" */),
  "component---src-pages-register-vc-aspx-js": () => import("./../../../src/pages/register-vc.aspx.js" /* webpackChunkName: "component---src-pages-register-vc-aspx-js" */),
  "component---src-pages-special-offer-aspx-js": () => import("./../../../src/pages/special-offer.aspx.js" /* webpackChunkName: "component---src-pages-special-offer-aspx-js" */),
  "component---src-pages-support-aspx-js": () => import("./../../../src/pages/support.aspx.js" /* webpackChunkName: "component---src-pages-support-aspx-js" */),
  "component---src-pages-uninstall-offer-aspx-js": () => import("./../../../src/pages/uninstall-offer.aspx.js" /* webpackChunkName: "component---src-pages-uninstall-offer-aspx-js" */),
  "component---src-pages-upgrade-now-aspx-js": () => import("./../../../src/pages/upgrade-now.aspx.js" /* webpackChunkName: "component---src-pages-upgrade-now-aspx-js" */),
  "component---src-pages-video-editing-aspx-js": () => import("./../../../src/pages/video-editing.aspx.js" /* webpackChunkName: "component---src-pages-video-editing-aspx-js" */),
  "component---src-pages-video-editing-software-aspx-js": () => import("./../../../src/pages/video-editing-software.aspx.js" /* webpackChunkName: "component---src-pages-video-editing-software-aspx-js" */),
  "component---src-pages-video-editor-aspx-js": () => import("./../../../src/pages/video-editor.aspx.js" /* webpackChunkName: "component---src-pages-video-editor-aspx-js" */),
  "component---src-pages-youtube-video-editor-2-aspx-js": () => import("./../../../src/pages/youtube-video-editor-2.aspx.js" /* webpackChunkName: "component---src-pages-youtube-video-editor-2-aspx-js" */),
  "component---src-pages-youtube-video-editor-aspx-js": () => import("./../../../src/pages/youtube-video-editor.aspx.js" /* webpackChunkName: "component---src-pages-youtube-video-editor-aspx-js" */)
}

